<template>
  <div>
    <div class="contain1">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待处理" name="first"></el-tab-pane>
        <el-tab-pane label="待上传设计" name="second"></el-tab-pane>
        <el-tab-pane label="处理记录" name="third">
          <el-form class="searchBox" :inline="true" size="small">
            <el-form-item label="工程编号/名称" >
              <el-input
                placeholder="请输入"
                v-model="pageParams.projectStr" clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="工程类型" label-width="80px">
              <el-select v-model="pageParams.projectType" clearable placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="处理结果" label-width="80px">
              <el-select
                v-model="pageParams.changeApproveResult"  clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in changeApproveResultDic"
                  :key="item.code"
                  :label="item.label"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设计变动"  label-width="80px">
              <el-select
                v-model="pageParams.changeRedesign"  clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in changeRedesignDic"
                  :key="item.code"
                  :label="item.label"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请时间"  label-width="100px">
              <el-date-picker
                v-model="applyDate"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss" clearable
                >
              </el-date-picker>
            </el-form-item>
            <el-button type="primary" @click="loadList(1)" size="small"
              >查询</el-button
            >
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <el-table
        :data="tableData"

        border
        stripe
        :height="activeName == 'third' ? 'calc(100vh - 418px)' : 'calc(100vh - 315px)'"
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          :prop="item.property"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.column.property === ''"></div>
            <div v-else>
              {{ scope.row[scope.column.property] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column  label="状态" align="center" width="100" v-if="activeName != 'first'">
          <template slot-scope="{row}">
            <el-tag :type="row.changeStatus == '待处理'?'info':(row.changeStatus == '处理中'?'':'success')">{{ row.changeStatus }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="150">
          <template slot-scope="scope">
            <div class="operateTool">
              <el-button
                type="primary"
                size="small"
                @click="onQuery(scope.row)"
                v-if="activeName === 'first'"
                >去处理</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="onQuery(scope.row)"
                v-if="activeName === 'second'"
                >上传</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="onQuery(scope.row)"
                v-if="activeName === 'third'"
                >查看</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    <el-dialog title="" :visible.sync="dialogVisible" width="50%" @close="coleseForm" :close-on-click-modal="false">
      <div v-if="activeName === 'first'">
        <div class="smallTitle">工程情况</div>
       
        <el-row>
          <el-col :span="12">
            <span class="label">项目编号：</span>
            <span class="">{{dataObj.projectNumber}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">工程名称：</span>
            <span class="">{{dataObj.projectName}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">工程地点：</span>
            <span class="">{{dataObj.projectSite}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">现场图片：</span>
            <span class="">
              <el-image  style="width: 100px; height: 100px;"
                          :src="dataObj.changeImageUrl[0]" 
                          :preview-src-list="dataObj.changeImageUrl">
              </el-image>
              <span v-if="dialogVisible" >共{{dataObj.changeImageUrl.length}}张</span>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">变更原因：</span>
            <span class="">{{dataObj.changeReason}}</span>
          </el-col>
        </el-row>
        <div class="smallTitle">原始设计</div>
      
        <el-row>
          <el-col :span="24">
            <span class="label">原始图纸：</span>
            <span class="">
              <span  v-for="(item, i) in dataObj.designFileUrl"
            :key="i + 'file34'">
               <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="top"
              >
                <span class="content-text">{{ item }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(item)"></el-button>
              </span>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">设计pdf：</span>
            <span class="">
              <el-tooltip
                class="item"
                effect="dark"
                :content="dataObj.designPdfUrl"
                placement="top"
              >
                <span class="content-text">{{ dataObj.designPdfUrl }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(dataObj.designPdfUrl)"></el-button>
            </span>
          </el-col>
        </el-row>
        <div class="smallTitle">处理结果</div>
    
        <el-form :model="pendingForm" ref="addform" :inline="true" :rules="rulesAdd" label-position="left">
           <el-row>
          <el-col :span="12">
          <el-form-item label="变更结果：" prop="changeApproveResult" label-width='100px'>
            <el-select v-model="pendingForm.changeApproveResult" placeholder="请选择">
              <el-option
                v-for="item in changeApproveResultDic"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="12" v-if="pendingForm.changeApproveResult==1">
          <el-form-item label="设计变动：" prop="changeRedesign" label-width='100px'>
            <el-select v-model="pendingForm.changeRedesign" placeholder="请选择">
              <el-option
                v-for="item in changeRedesignDic"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          </el-col>
          <el-col :span="24">
          <el-form-item label="处理说明：" label-width='100px'>
            <el-input
              style="width: 677px"
              type="textarea"
              maxlength="100"
              placeholder="请输入"
              v-model="pendingForm.changeApproveDescription"
            ></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        </el-form>
      </div>
      <el-form
        label-width="100px"
        :model="pendingUploadForm"
        :rules="rules"
        v-else-if="activeName === 'second'"
      >
        <el-form-item label="">
          <span slot="label">
          <span style="color:red;margin-left:4px">*</span>  原始图纸：
            </span>
          <el-upload
            action="#"
            :auto-upload="true"
            accept="*"
            :file-list="fileList"
            :limit="20"
            :http-request="onFileList"
            :on-remove="onDeleteFileList"
            :on-change="beforHFhandleChangeImg"
            :on-exceed="msgLimit"
          >
            <div class="uploadBtn">
              <el-button size="small" type="primary">点击上传</el-button>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="">
           <span slot="label">
          <span style="color:red;margin-left:4px">*</span>  设计pdf：
            </span>
          <el-upload
            action="#"
            :auto-upload="true"
            :http-request="onPdfUpload"
            :on-remove="onDeletePdfList"
            :on-change="beforHFhandleChangePDF"
            accept=".pdf,.PDF"
            :file-list="fileListPDF"
            :limit="1"
            :on-exceed="msgLimit1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="设计说明：">
          <el-input
            type="textarea"
            v-model="pendingUploadForm.changeRedesignDescription"
            :autosize="{ minRows: 2, maxRows: 4 }"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div v-else>
       
        <div class="smallTitle">申请信息</div>
        <el-row>
          <el-col :span="12">
            <span class="label">项目编号：</span>
            <span class="">{{dataObj.projectNumber}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">工程名称：</span>
            <span class="">{{dataObj.projectName}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">工程地点：</span>
            <span class="">{{dataObj.projectSite}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">现场图片：</span>
            <span class=""><el-image  style="width: 100px; height: 100px"
                          :src="dataObj.changeImageUrl[0]" 
                          :preview-src-list="dataObj.changeImageUrl">
              </el-image>
              <span v-if="dialogVisible"
                >共{{ dataObj.changeImageUrl.length }}张</span
              ></span
            >
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">变更原因：</span>
            <span class="">{{dataObj.changeReason}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <span class="label">提交人：</span>
            <span class="">{{dataObj.changeApplyRealname}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">提交时间：</span>
            <span class="">{{dataObj.changeApplyTime}}</span>
          </el-col>
        </el-row>
        <div class="smallTitle">原始设计</div>
        <el-row>
          <el-col :span="24">
            <span class="label">原始图纸：</span>
            <span class="">
              <span  v-for="(item, i) in dataObj.designFileUrl"
            :key="i + 'file'">
               <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="top"
              >
                <span class="content-text">{{ item }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(item)"></el-button>
              </span>
            </span>
          </el-col>
          <el-col :span="24">
            <span class="label">设计pdf：</span>
            <span class="">
             <el-tooltip
                class="item"
                effect="dark"
                :content="dataObj.designPdfUrl"
                placement="top"
              >
                <span class="content-text">{{ dataObj.designPdfUrl }}</span>
              </el-tooltip>
              <el-button
                type="text"
                icon="el-icon-download"
                @click="onDownFile(dataObj.designPdfUrl)"
              ></el-button>
            </span>
          </el-col>
        </el-row>
         <div class="smallTitle">处理结果</div>
      
        <el-row>
          <el-col :span="12">
            <span class="label">变更结果：</span>
            <span class="">{{dataObj.changeApproveResult}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">设计变动：</span>
            <span class="">{{dataObj.changeRedesign}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">处理说明：</span>
            <span class="">{{dataObj.changeApproveDescription}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <span class="label">处理人：</span>
            <span class="">{{dataObj.changeApproveRealname}}</span>
          </el-col>
          <el-col :span="12">
            <span class="label">处理时间：</span>
            <span class="">{{dataObj.changeApproveTime}}</span>
          </el-col>
        </el-row>
        <div v-if="dataObj.changeRedesignFileUrl.length>0">
        <el-row >
          <el-col :span="24">
            <span class="label">变更图纸：</span>
            <span class="">
                <span  v-for="(item, i) in dataObj.changeRedesignFileUrl"
            :key="i + 'file'">
               <el-tooltip
                class="item"
                effect="dark"
                :content="item"
                placement="top"
              >
                <span class="content-text">{{ item }}</span>
              </el-tooltip>
              <el-button type="text" icon="el-icon-download" @click="onDownFile(item)"></el-button>
              </span>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">变更pdf：</span>
            <span class="" v-if="dataObj.changeRedesignPdfUrl">
              <el-tooltip
                class="item"
                effect="dark"
                :content="dataObj.changeRedesignPdfUrl"
                placement="top"
              >
                <span class="content-text">{{
                  dataObj.changeRedesignPdfUrl
                }}</span>
              </el-tooltip>
              <el-button
                type="text"
                icon="el-icon-download"
                @click="onDownFile(dataObj.changeRedesignPdfUrl)"
              ></el-button>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">设计说明：</span>
            <span class="">{{dataObj.changeRedesignDescription}}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <span class="label">上传时间：</span>
            <span class="">{{dataObj.changeApproveTime}}</span>
          </el-col>
        </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="activeName !== 'third'">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          v-if="activeName === 'first'"
          @click="addSave(1)"
          >保 存</el-button
        >
        
        <el-button type="primary" @click="addSave(2)" v-else>保 存</el-button>
      </span>
      <span slot="footer" class="dialog-footer" v-else>
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import { selectDictListByParentCode } from "@/apis/commonType";
import axios from "@/apis/designApi";
import {designChange_selectDesignChangeDetail,designChange_uploadDesignChange,designChange_approveDesignChange} from '../../RequestPort/engineering/engineering'

import getNowFormatDate from "./../../common/js/nowDate.js";
export default {
  mixins: [pageMixins],
  data() {
    return {
      activeName: "first", //  tab选择的名称
      tableHeader: [], //  表头数据
      pendingTableHeader: [
        { label: "工程编号", property: "projectNumber" },
        { label: "工程名称", property: "projectName" },
        { label: "工程类型", property: "projectType" },
        { label: "安装施工队", property: "teamName" },
        { label: "变更原因", property: "changeReason" },
        { label: "现场管理人员", property: "siteManager" },
        { label: "提交人", property: "changeApplyRealname" },
        { label: "提交时间", property: "changeApplyTime" },
      ], //  待处理表头数据
      pendingUploadHeader: [
        { label: "变更结果", property: "changeApproveResult" },
        { label: "设计变动", property: "changeRedesign" },
        { label: "设计图上传情况", property: "fileUploadFlag" },

      ], //  待上传表头数据
      processRecordsHeader: [
        { label: "工程编号", property: "projectNumber" },
        { label: "工程名称", property: "projectName" },
        { label: "工程类型", property: "projectType" },
        { label: "安装施工队", property: "teamName" },
        { label: "变更原因", property: "changeReason" },
        { label: "现场管理人员", property: "siteManager" },
        { label: "申请人", property: "changeApplyRealname" },
        { label: "申请时间", property: "changeApplyTime" },
        { label: "处理人", property: "changeApproveRealname" },
        { label: "处理时间", property: "changeApproveTime" },
        { label: "变更结果", property: "changeApproveResult" },
        { label: "设计变动", property: "changeRedesign" },
        { label: "设计图上传情况", property: "fileUploadFlag" },
      ],
      tableData: [], //  表格数据
      pendingForm: {
        changeReason: "",
        changeRedesign: "",
        changeApproveResult: "",
      },
      total: 0,
      pendingUploadForm: {
        file: [],
        pdf: [],
        remark: "",
      },
      rules: {
        result: [{ required: true, message: "请选择", trigger: "change" }],
        change: [{ required: true, message: "请选择", trigger: "change" }],
        file: [{ required: true, message: "请选择", trigger: "change" }],
        pdf: [{ required: true, message: "请选择", trigger: "change" }],
        remark: [
          { max: 100, message: "超出限制", trigger: ["change", "blur"] },
        ],
      },
       rulesAdd: {
        changeApproveResult: [{ required: true, message: "请选择", trigger: "change" }],
        changeRedesign: [{ required: true, message: "请选择", trigger: "change" }],
        
      },
      pageParams: {
        current: 1,
        size: 100,
        searchType: 1,
      },
      searchForm: {
        projectStr: null,
        changeRedesign: null,
        projectType: null,
        searchType: null,
      },
      changeApproveResultDic: [
        { label: "同意变更", code: 1 },
        { label: "拒绝变更", code: 2 },
      ], //  处理结果字典
      changeRedesignDic: [
        { label: "需要调整设计", code: 1 },
        { label: "无需调整设计", code: 2 },
      ], //  设计变动字典
      searchTypeDic: [
        { label: "待处理", code: 1 },
        { label: "待上传", code: 2 },
        { label: "处理记录", code: 3 },
      ], //  查询类型字典
      options: [], //  字典（占位）
      dialogVisible: false, //  弹窗开关.
      applyDate:[], //  申请时间
      dataObj:{changeImageUrl:[],changeRedesignFileUrl:[],projectNumber:''}, //  详情
      firstImg:'',  //  图片
      fileList:[],
      fileListPDF:[],
      repairBeforFilefileList:[],
      repairBeforFilefileListPDF:[]
    };
  },
  mounted() {
    this.handleClick();
    this.getOption();
  },
  methods: {
    onDownFile(url) {
      window.open(url);
    },
    /**tab切换 */
    handleClick() {
      let {
        activeName,
        pendingTableHeader,
        pendingUploadHeader,
        processRecordsHeader,
      } = this;
      switch (activeName) {
        case "first":
          this.tableHeader = [...pendingTableHeader];
          this.pageParams.searchType = 1;
          break;
        case "second":
          this.tableHeader = [...pendingTableHeader, ...pendingUploadHeader];
          this.pageParams.searchType = 2;
          break;
        case "third":
          this.tableHeader = processRecordsHeader;
          this.pageParams.searchType = 3;
          break;
      }
      this.loadList(1);
    },
    /**加载表格 */
    async loadList(page) {
      if (page) {
        this.pageParams.current = 1;
      }
      this.pageParams.startTime = "";
      this.pageParams.endTime = "";
      if (this.applyDate.length > 0) {
        this.pageParams.startTime = this.applyDate[0];
        this.pageParams.endTime = this.applyDate[1];
      }
      let { pageParams } = this;
      try {
        let res = await axios.getDesignTableData(pageParams);
        this.tableData = res.data.records;
        this.total = res.data.total;
      } catch (e) {
        this.tableData = [];
      }
    },
    getOption(){
      let code ='2100';
        selectDictListByParentCode({parentCode:code}).then(res=>{
          if(res.code==200){
            this.options = res.data
          }
        })
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.loadList(1);
    },
    handleCurrentChange(val) {
      this.pageParams.current = val;
      this.loadList();
    },
    /**点击去处理按钮 */
    onQuery(row) {
      this.pendingForm={
        result: "",
        change: "",
        remark: "",
      }
      designChange_selectDesignChangeDetail({changeId:row.id}).then(ret=>{
        if(ret.data){
          this.dataObj = ret.data;
          if(ret.data.designFileUrl){
            this.dataObj.designFileUrl = ret.data.designFileUrl.split(',')
          }else{
            this.dataObj.designFileUrl = []
          }
          if(ret.data.changeRedesignFileUrl){
            this.dataObj.changeRedesignFileUrl = ret.data.changeRedesignFileUrl.split(',')
          }else{
            this.dataObj.changeRedesignFileUrl = []
          }
          
          if(ret.data.changeImageUrl){
            this.dataObj.changeImageUrl = ret.data.changeImageUrl.split(',')
          }else{
            this.dataObj.changeImageUrl = []
          }
         
          this.dialogVisible =true
        }
          
      })
      this.dialogVisible =true
      
    },
     
    addSave(type){
      var data = {};
      if(type==1){
        
        data = this.pendingForm
        data.id = this.dataObj.id
        this.$refs['addform'].validate(valid => {
            if (valid) {
                designChange_approveDesignChange(data).then(res=>{
                    if(res.code==200){
                      this.$message.success('处理成功');
                      this.loadList()
                        this.dialogVisible = false
                    }else{
                      this.$message.error(res.msg);
                    }
                  })
            }
            })
      }
      if(type==2){
        data.id = this.dataObj.id
        data.changeRedesignFileUrl = this.pendingUploadForm.file.join(',');
        data.changeRedesignPdfUrl = this.pendingUploadForm.pdf.join(',');
        data.changeRedesignDescription = this.pendingUploadForm.changeRedesignDescription;
       
        if(!data.changeRedesignFileUrl){
          this.$message.error('请上传原始图纸');
          return
        }
        if(!data.changeRedesignPdfUrl){
           this.$message.error('请上传设计pdf');
          return
        }
         designChange_uploadDesignChange(data).then(res=>{
            if(res.code==200){
              this.$message.success('处理成功');
              this.loadList()
                this.dialogVisible = false
            }else{
              this.$message.error(res.msg);
            }
          })
      }
     
     
    },

    //  上传文件
    async onFileList(files) {
      try {
         let projectName = this.dataObj.projectName;
      
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        // uploadObj.append("text", '工程名称：'+projectName+'##当前时间：'+getNowFormatDate()+'##用户名：'+localStorage.getItem("userName"));
    
        let res = await axios.uploadImg(uploadObj);
        this.pendingUploadForm.file.push(res.data.url);
      } catch (e) {}
    },
    beforHFhandleChangePDF(file, fileList) {
      this.repairBeforFilefileListPDF = fileList
    },
     beforHFhandleChangeImg(file, fileList) {
      this.repairBeforFilefileList = fileList
    },
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
        if (arr[i] === obj) {
          return i;
        }
      }
      return -1;
    },
    // 删除文件
    onDeleteFileList(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileList,file);
       this.pendingUploadForm.file.splice(index,1)
      
    },
   
    async onPdfUpload(files) {
      try {
        let uploadObj = new FormData();
        uploadObj.append("file", files.file);
        let res = await axios.uploadImg(uploadObj);
        this.pendingUploadForm.pdf.push(res.data.url);
      } catch (e) {
        console.log(e, "e");
      }
    },
     /**
     * 删除pdf
     */
    onDeletePdfList(file) {
      let index = this.getArrayIndex(this.repairBeforFilefileListPDF,file);
      this.pendingUploadForm.pdf.splice(index,1)
    },
    
    
    msgLimit() {
      this.$message.error("最多上传20张图片");
    },
    msgLimit1() {
      this.$message.error("只能上传一个pdf");
    },
    coleseForm(){
      this.pendingUploadForm = {file: [],
        pdf: [],
        remark: ""
        }
        if(this.pendingUploadForm.changeRedesignDescription){
          this.pendingUploadForm.changeRedesignDescription=''
        }
      if(this.$refs['addform']){
            this.$refs['addform'].resetFields();
        }
        this.fileListPDF=[]
         this.fileList=[]
      this.dataObj={changeImageUrl:[],changeRedesignFileUrl:[],projectNumber:''}
    }
  },
};
</script>

<style lang="scss" scoped>
.label{height: 40px;line-height: 40px;}
.smallTitle {
  font-size: 16px;
  margin: 15px 0;
  color: #303133;
  &::before {
    content: "";
    display: inline-block;
    height: 16px;
    width: 3px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #1082ff;
  }
}
</style>