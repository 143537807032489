import axios from '@/common/js/request'

export function designChange_selectDesignChangeDetail(params){
    const designChange_selectDesignChangeDetail = axios({
        url: 'designChange/selectDesignChangeDetail',
        method: 'get',
        params
    })
    return designChange_selectDesignChangeDetail
}
export function designChange_uploadDesignChange(params){
    const designChange_uploadDesignChange = axios({
        url: 'designChange/uploadDesignChange',
        method: 'post',
        data:params
    })
    return designChange_uploadDesignChange
}
export function designChange_approveDesignChange(params){
    const designChange_approveDesignChange = axios({
        url: 'designChange/approveDesignChange',
        method: 'post',
        data:params
    })
    return designChange_approveDesignChange
}